/* eslint-disable no-console */
const backupError = console.error;
const backupWarn = console.warn;

const suppressedErrors = [
  "`@import` rules must be before all other types of rules in a stylesheet",
  "React.Fragment can only have `key` and `children` props.",
  /.+component is changing \w+ uncontrolled .+ to be controlled/,
];
const suppressedWarnings = ["MUI: You have provided an out-of-range value"];

console.error = function filterErrors(msg) {
  if (typeof msg === "string") {
    if (suppressedErrors.some((entry) => !!msg.match(entry))) {
      return;
    }
  }
  backupError.apply(console, arguments);
};

console.warn = function filterWarnings(msg) {
  if (typeof msg === "string") {
    if (suppressedWarnings.some((entry) => !!msg.match(entry))) {
      return;
    }
  }
  backupWarn.apply(console, arguments);
};
