import MuiTable from "@mui/material/Table";
import {cx} from "@emotion/css";
import React from "react";

import {ITable} from "~/components/table";

import {styles} from "./Table.styles";
import {BmTableSettingsContextProvider} from "../TableSettings.context";

export const BmTable: React.FC<ITable> = ({sx, className, newMobileDesign = false, children}) => {
  return (
    <BmTableSettingsContextProvider value={{newMobileDesign}}>
      <MuiTable sx={sx} className={cx(styles.table, className)}>
        {children}
      </MuiTable>
    </BmTableSettingsContextProvider>
  );
};
