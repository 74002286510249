import {add, set} from "date-fns";

/* regexps */
export const EMAIL_REGEXP = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
export const LETTERS_NUMBERS_REGEXP = /^[a-zA-Z0-9]+$/;
// taken from https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
export const YOUTUBE_VIDEO_REGEXP = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
//taken from https://stackoverflow.com/questions/5008609/vimeo-video-link-regex
export const VIMEO_VIDEO_REGEXP =
  /^https?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
//taken from https://stackoverflow.com/questions/5612602/improving-regex-for-parsing-youtube-vimeo-urls
export const YOUTUBE_AND_VIMEO_REGEXP =
  /^https?:\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/;
// taken from https://uibakery.io/regex-library/url
export const URL_REGEXP =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_.~#?&/=]*)$/;

// TRANSITION ms
export const TRANSITION_TIME = 350;

// TOAST TRANSITION
export const TOAST_TRANSITION = 3000;

// DEFAULT PACKAGING WEIGHT
export const DEFAULT_PACKAGING_WEIGHT = 0.5;

export const REQUEST_ACCESS_LINK = "https://share-eu1.hsforms.com/1eOWAMuGIQF6e0zoAVtBK4Qf8gr6";
export const GOOGLE_MAPS_BASE_LINK = "https://maps.google.com/?q=";
export const BEMAKERS_LINK = "https://bemakers.com/";
export const BEMAKERS_HELP_CENTER_LINK = "https://support.bemakers.com/hc/en-us";
export const WATS_NEW_URL = "https://support.bemakers.com/hc/en-us/categories/5613711201949-Bemakers-Update";
export const GDPR_LINK = "https://gdpr.eu/what-is-gdpr/";
export const SEED_LINK =
  "https://ec.europa.eu/taxation_customs/dds2/seed/seed_consultation.jsp?Lang=en&Expand=true&offset=1&Excnr=SK52002100023";
export const BOOK_CALL_LINK = "https://meetings-eu1.hubspot.com/louise-reinhardt/csm?_hsmi=2";
export const EXCISE_DUTY_CALCULATOR_LINK = "https://bemakers.com/excise-duty-calculator";
export const BEMAKERS_PRICING_LINK = "https://bemakers.com/pricing";
export const BEMAKERS_CN_CODE = "https://support.bemakers.com/hc/en-us/articles/5915717805981-What-is-a-CN-Code";

export const TOMORROW = add(new Date(), {
  days: 1,
});
export const TODAY_MIDNIGHT = set(new Date(), {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
});
export const TOMORROW_MIDNIGHT = add(TODAY_MIDNIGHT, {days: 1});
export const AFTER_TOMORROW_MIDNIGHT = add(TOMORROW_MIDNIGHT, {days: 1});
export const BEMAKERS_INIT_DATE = new Date(2021, 0, 0);

export const ALL_PACKAGING_CODES = ["BO", "BQ", "BW", "CX", "CT", "CW", "KG", "PO", "PB", "PP"];
